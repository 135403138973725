<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Profile Details</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6"></div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">   
                        <b-overlay :show="loader">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="edit_name">Company Name <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="edit_name" v-model="form_edit.name" class="form-control" placeholder="Enter Area Name">
                                        <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                                        <input type="hidden" name="id" v-model="form_edit.id" />
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="mobile_1">Mobile 1 <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="mobile_1" v-model="form_edit.mobile_1" class="form-control" placeholder="Enter Mobile 1" required>
                                        <span class="text-danger" v-if="errors.mobile_1">{{ errors.mobile_1[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="mobile_1">Mobile 2 </label>
                                        <input type="text" id="mobile_2" v-model="form_edit.mobile_2" class="form-control" placeholder="Enter Mobile 2">
                                        <span class="text-danger" v-if="errors.mobile_2">{{ errors.mobile_2[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="email_1">Email 1 <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="email_1" v-model="form_edit.email_1" class="form-control" placeholder="Enter Email 1" required>
                                        <span class="text-danger" v-if="errors.email_1">{{ errors.email_1[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="email_2">Email 2 </label>
                                        <input type="text" id="email_2" v-model="form_edit.email_2" class="form-control" placeholder="Enter Email 2">
                                        <span class="text-danger" v-if="errors.email_2">{{ errors.email_2[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="address">Address</label>
                                        <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Enter Address">
                                        <span class="text-danger" v-if="errors.address">{{ errors.address[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="address">SMS Service </label>
                                        <select class="form-control" v-model="form_edit.sms_service">
                                            <option :value="1">On</option>
                                            <option :value="2">Off</option>
                                        </select>
                                        <span class="text-danger" v-if="errors.address">{{ errors.address[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="otp_status">OTP Status </label>
                                        <v-select name="otp_status"
                                            multiple
                                            v-model="form_edit.otp_status"
                                            label="text"
                                            :reduce="item => item.id"
                                            :options= otpStatusList
                                            :placeholder="$t('globalTrans.select')"
                                        />
                                        <span class="text-danger" v-if="errors.otp_status">{{ errors.otp_status[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="delivery_sla_isd">Delivery SLA (ISD) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="delivery_sla_isd" v-model="form_edit.delivery_sla_isd" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.delivery_sla_isd">{{ errors.delivery_sla_isd[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="delivery_sla_osd">Delivery SLA (OSD) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="delivery_sla_osd" v-model="form_edit.delivery_sla_osd" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.delivery_sla_osd">{{ errors.delivery_sla_osd[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="delivery_sla_sa">Delivery SLA (SA) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="delivery_sla_sa" v-model="form_edit.delivery_sla_sa" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.delivery_sla_sa">{{ errors.delivery_sla_sa[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="return_sla_isd">Return SLA (ISD) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="return_sla_isd" v-model="form_edit.return_sla_isd" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.return_sla_isd">{{ errors.return_sla_isd[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="return_sla_osd">Return SLA (OSD) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="return_sla_osd" v-model="form_edit.return_sla_osd" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.return_sla_osd">{{ errors.return_sla_osd[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="return_sla_sa">Return SLA (SA) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="return_sla_sa" v-model="form_edit.return_sla_sa" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.return_sla_sa">{{ errors.return_sla_osd[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="payment_sla_isd">Payment SLA (ISD) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="payment_sla_isd" v-model="form_edit.payment_sla_isd" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.payment_sla_isd">{{ errors.payment_sla_isd[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="payment_sla_osd">Payment SLA (OSD) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="payment_sla_osd" v-model="form_edit.payment_sla_osd" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.payment_sla_osd">{{ errors.payment_sla_osd[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="payment_sla_sa">Payment SLA (Sa) <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="payment_sla_sa" v-model="form_edit.payment_sla_sa" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                        <span class="text-danger" v-if="errors.payment_sla_sa">{{ errors.payment_sla_sa[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-sm btn-info mr-2" @click.prevent="updateProfile">Update</button>
                                        <button type="button" class="btn btn-sm btn-danger">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </div>
                <div class="card mt-25">
                    <div class="card-body">   
                        <b-overlay :show="mailLoader">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="email">Email <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" id="email" v-model="form.email" class="form-control" placeholder="Email Address">
                                        <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <label for="message">Message <span class="text-danger" title="Required">*</span></label>
                                        <textarea id="message" v-model="form.message" class="form-control" placeholder="Enter your message" rows="10" required> </textarea>
                                        <span class="text-danger" v-if="errors.message">{{ errors.message[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-sm btn-info mr-2" @click.prevent="sendEmail">Send</button>
                                        <button type="button" class="btn btn-sm btn-danger">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
  </div>
</template>
<script>
import config from '@/config'
export default {
    name:'Area',
    data(){
        return {
            loader: false,
            mailLoader: false,
            form_edit:'',
            form : {
                email: '',
                message: ''
            },
            otpStatusList: [
                { id: 12, text: 'Cancel Request'},
                { id: 13, text: 'Partial Request'},
                { id: 14, text: 'Delivered'},
                { id: 15, text: 'Partial Delivered'},
                { id: 17, text: 'Hold'},
                { id: 18, text: 'Reschedule'},
                { id: 19, text: 'Exchanged'},
                { id: 20, text: 'Cancel'}
            ],
            errors:[]
        }
    },
    created(){
        this.getProfile();
    },
    methods:{
        getProfile(){      
            this.loader = true      
            config.getData('/profile')
            .then((response) => {
                this.loader = false
                this.form_edit = response.data;
                if (response.data.otp_status) {
                    this.form_edit.otp_status = response.data.otp_status ? JSON.parse(response.data.otp_status) : response.data
                } else {
                    this.form_edit = response.data;  
                }
            });
        },
        async updateProfile(){
            this.loader = true
            const response = await config.postData('/profile/update', this.form_edit)
            this.loader = false
            if (response.status == 201) {
                this.$toast.success({
                    title: 'Success',
                    message: 'Profile Updated Successfully',
                    color: '#218838'
                })
                this.editModal = false;   
                this.getProfile();    
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })
            }
        },
        async sendEmail(){
            this.mailLoader = true
            const response = await config.postData('/email/send', this.form)
            this.mailLoader = false
            console.log('response = ', response)
            // if (response.status == 201) {
            //     this.$toast.success({
            //         title: 'Success',
            //         message: 'Email Send Successfully',
            //         color: '#218838'
            //     })
            //     this.editModal = false;   
            //     this.getProfile();    
            // } else {
            //     this.$toast.error({
            //         title: 'Error',
            //         message: 'Email or password not match'
            //     })
            // }
        }
    }
}
</script>